import { FC } from "react";
import { FaWindowClose } from "react-icons/fa";

interface IProps {
  handleClick: () => void;
  className?: string;
}

const CloseIcon: FC<IProps> = (props) => {
  const { className } = props;

  const classes = className ? `close-icon ${className}` : "close-icon";

  return (
    <div className={classes} onClick={props.handleClick}>
      <FaWindowClose size={20} />
    </div>
  );
};

export default CloseIcon;
