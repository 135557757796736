import { FC } from "react";
import { Link } from "react-router-dom";

interface IProps {
  align: string | "left" | "right" | "center";
  content: string;
  header: string;
  link: string;
  internal: boolean;
}

const renderLink = (header: string, link: string, internal: boolean) => {
  if (internal) {
    return <Link to={link}>{header}</Link>;
  }
  return (
    <a href={link} rel="noreferrer noopener" target="_blank">
      {header}
    </a>
  );
};

const HomeContainers: FC<IProps> = (props) => {
  let alignFlex = "center";

  switch (props.align) {
    case "left":
      alignFlex = "flex-end";
      break;
    case "right":
      alignFlex = "flex-start";
      break;
    default:
      break;
  }

  return (
    <div className="home-container rounded" style={{ alignSelf: alignFlex }}>
      <strong>{renderLink(props.header, props.link, props.internal)}</strong>
      <p>{props.content}</p>
    </div>
  );
};

export default HomeContainers;
