import _ from "lodash";

// Lodash search function
export const searchByQuery = (
  collection: object[],
  include: string[],
  query: string
): any[] => {
  query = _.toLower(query);
  return _.filter(collection, function (object) {
    return _(object)
      .pick(include)
      .some((string) => {
        return _(string).toLower().includes(query);
      });
  });
};
