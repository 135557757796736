import { FC } from "react";
import { ReactComponent as Logo } from "../../assets/logo.svg";

const HomeDescriptionModule: FC = () => {
  return (
    <div className="flex-btw-center home-description-module">
      <p>
        On this page you will find tutorials and a portfolio section.
        <br />I work as a web developer mostly in: Javascript, Typescript and
        React.
        <br />
        For the backend-stuff I use PHP / Node.js / databases and more...
        <br />
        <br />
        Very interested in Linux and been using various distributions for years
        <br />
        You can se some of my work on the portfolio and tutorials tab.
      </p>
      <Logo />
    </div>
  );
};

export default HomeDescriptionModule;
