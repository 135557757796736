import { FC } from "react";
import { Helmet } from "react-helmet-async";
import { containersData } from "../../data/homeContainers";
import HomeDescriptionModule from "./HomeDescriptionModule";
import HomeContainer from "./HomeContainer";
import banner from "../../assets/banner.png";

interface IProps {}

const pageTitle = "Fredrik | Home";
const pageAbout = "Welcome to my website - Tutorials, downloads and more";
const pageKeys = "Freddan, Fps_sWe, F3L, FreLee54, Leemann, Home";
const pageUrl = `${process.env.REACT_APP_WEBSITE_URL}/home`;

const renderHomeContainers = () => {
  return containersData.map((obj) => (
    <HomeContainer
      internal={obj.internal}
      content={obj.content}
      header={obj.header}
      align={obj.align}
      link={obj.link}
      key={obj.link}
    />
  ));
};

const Home: FC<IProps> = (props) => {
  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={pageAbout} />
        <meta name="keywords" content={pageKeys} />
        <link rel="canonical" href={pageUrl} />
      </Helmet>
      <main className="flex-available-height">
        <h1 className="noselect">WELCOME</h1>
        <div className="home-into">
          <strong>Hello my name is Fredrik. This is my homepage!</strong>
        </div>
        <section className="content-section rounded flex-available-height">
          <HomeDescriptionModule />
          <figure className="home-banner-figure">
            <img src={banner} alt="" />
            <figcaption>
              I have a graduation in network technology and I also like
              games/software, Linux and hardware. This banner picture shall
              represent some of that...
            </figcaption>
          </figure>
          {renderHomeContainers()}
          <strong className="home-outro">
            Questions or suggestions for improvements?
            <br />
            Please do not hesitate to contact me.
          </strong>
        </section>
      </main>
    </>
  );
};

export default Home;
