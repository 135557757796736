import { FC, useEffect, useState, useCallback } from "react";
import { Helmet } from "react-helmet-async";
import { orderBy } from "lodash";
import { searchByQuery } from "../../utils/searchFunction";
import axios from "axios";
import { ITutorial, TResTutorials } from "./tutorialsTypes";
import SearchBar from "../../components/SearchBar";
import TutorialListing from "./TutorialListing";

interface IProps {}

const pageTitle = "Fredrik | Tutorials";
const pageAbout = "My Tutorials, a listing for my tutorial-like blog-posts";
const pageKeys = "Freddan, Fps_sWe, F3L, FreLee54, Website, Tutorials, GitHub";
const pageUrl = `${process.env.REACT_APP_WEBSITE_URL}/tutorials`;

const searchBarHelperText = "Search for: title / date-updated";

const tutorialsApiUrl = process.env.REACT_APP_TUTORIALS_API_URL!;

const renderTutorials = (tutorials: ITutorial[]) => {
  return tutorials.map((obj) => (
    <TutorialListing
      updated={obj.updated}
      title={obj.title}
      link={obj.link}
      key={obj.link}
    />
  ));
};

const Tutorials: FC<IProps> = (props) => {
  const [availableTutorials, setAvailableTutorials] = useState<ITutorial[]>([]);
  const [filteredTutorials, setFilteredTutorials] = useState<ITutorial[]>([]);

  const totalTutorials = availableTutorials.length;
  const totalFilteredTutorials = filteredTutorials.length;

  const handleSearch = useCallback(
    (searchQuery: string) => {
      const searchResult = searchByQuery(
        availableTutorials,
        ["title", "updated"],
        searchQuery
      );
      const orderedResult = orderBy(searchResult, ["updated"], ["desc"]);
      setFilteredTutorials(orderedResult);
    },
    [availableTutorials, setFilteredTutorials]
  );

  const getAvailableTutorials = useCallback(
    async (controller: AbortController) => {
      try {
        const res: TResTutorials = await axios.get(tutorialsApiUrl, {
          signal: controller.signal,
        });
        if (res.status !== 200) throw new Error(`Status ${res.status}`);
        const orderedTutorials = orderBy(res.data, ["updated"], ["desc"]);
        setAvailableTutorials(orderedTutorials);
        setFilteredTutorials(orderedTutorials);
      } catch (error) {
        console.error(error);
      }
    },
    [setAvailableTutorials, setFilteredTutorials]
  );

  useEffect(() => {
    const tutorialsController = new AbortController();
    getAvailableTutorials(tutorialsController);
    return () => {
      tutorialsController.abort();
    };
  }, [getAvailableTutorials]);

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={pageAbout} />
        <meta name="keywords" content={pageKeys} />
        <link rel="canonical" href={pageUrl} />
      </Helmet>
      <main className="flex-available-height">
        <h1 className="noselect">TUTORIALS</h1>
        <SearchBar
          totalResults={totalTutorials}
          filteredResults={totalFilteredTutorials}
          helperText={searchBarHelperText}
          searchFunction={handleSearch}
        />
        <section className="content-section rounded flex-available-height">
          {renderTutorials(filteredTutorials)}
        </section>
      </main>
    </>
  );
};

export default Tutorials;
