import { FC, RefObject, useCallback, useEffect, useRef } from "react";
import { random } from "lodash";

interface IProps {
  captchaValid: boolean;
  setCaptchaValid: React.Dispatch<React.SetStateAction<boolean>>;
  captchaSecret: string;
}

const separators = ["&", "|", "#", ".", "*", "/", "!", "¤", "@", "%"];

const ContactFormCaptcha: FC<IProps> = (props) => {
  const canvasRef: RefObject<HTMLCanvasElement> = useRef(null);

  const { captchaValid, setCaptchaValid, captchaSecret } = props;

  const inputViridityClass = captchaValid ? "valid" : "invalid";

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const captchaUser = e.target.value;
      if (captchaUser === captchaSecret) {
        setCaptchaValid(true);
      } else {
        setCaptchaValid(false);
      }
    },
    [setCaptchaValid, captchaSecret]
  );

  useEffect(() => {
    if (canvasRef.current instanceof HTMLCanvasElement) {
      const separator = separators[random(0, separators.length - 1)];
      const captchaPattern = captchaSecret.split("").join(separator);
      const canvas = canvasRef.current;
      const ctx = canvas.getContext("2d")!;
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      ctx.fillStyle = "black";
      ctx.fillRect(0, 0, canvas.width, canvas.height);
      ctx.font = "20px Courier";
      ctx.fillStyle = "white";
      ctx.strokeStyle = "white";
      ctx.textAlign = "center";
      ctx.strokeText(captchaPattern, canvas.width / 2, 21);
    }
  }, [captchaSecret]);

  return (
    <div>
      <canvas
        ref={canvasRef}
        width="320px"
        height="30px"
        style={{ display: "block" }}
      ></canvas>
      <div className="form-group">
        <input
          placeholder="Enter the numbers above"
          className={inputViridityClass}
          onChange={handleChange}
          maxLength={8}
          type="text"
          required
        />
        <small>Only enter the numbers that you se above</small>
      </div>
    </div>
  );
};

export default ContactFormCaptcha;
