import { FC } from "react";
import { FaSearch } from "react-icons/fa";

interface IProps {
  helperText: string;
  searchFunction: (searchQuery: string) => void;
  totalResults: number;
  filteredResults: number;
}

const SearchBar: FC<IProps> = (props) => {
  return (
    <div className="search-container">
      <div className="search-bar">
        <div className="search-box rounded">
          <FaSearch size={16} />
          <input
            type="search"
            placeholder="search..."
            onChange={(e) => props.searchFunction(e.target.value)}
          />
        </div>
        <strong>{`Results: ${props.filteredResults} / ${props.totalResults}`}</strong>
      </div>
      <small>{props.helperText}</small>
    </div>
  );
};

export default SearchBar;
