import { FC } from "react";
import { NavLink } from "react-router-dom";
import {
  FaEnvelope,
  FaGithubAlt,
  FaLinkedinIn,
  FaInstagram,
  FaTwitter,
} from "react-icons/fa";

const SOCIAL_ICON_SIZE = 25;
const ACTIVE_CLASS_NAME = "active";

interface IProps {
  showContactForm: boolean;
  setShowContactForm: React.Dispatch<React.SetStateAction<boolean>>;
}

const Header: FC<IProps> = (props) => {
  const { showContactForm, setShowContactForm } = props;

  const contactIconActive = showContactForm ? "active" : undefined;

  return (
    <header className="page-header rounded flex-btw-center">
      <nav role="navigation" aria-label="Main menu">
        <ul className="menu-list menu-list--main">
          <li>
            <NavLink
              to="/home"
              className={({ isActive }) =>
                isActive ? ACTIVE_CLASS_NAME : undefined
              }
            >
              HOME
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/tutorials"
              className={({ isActive }) =>
                isActive ? ACTIVE_CLASS_NAME : undefined
              }
            >
              TUTORIALS
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/portfolio"
              className={({ isActive }) =>
                isActive ? ACTIVE_CLASS_NAME : undefined
              }
            >
              PORTFOLIO
            </NavLink>
          </li>
        </ul>
      </nav>
      <nav role="navigation" aria-label="Socials menu">
        <ul className="menu-list menu-list--socials">
          <li>
            <FaEnvelope
              tabIndex={0}
              title="Contact Me"
              size={SOCIAL_ICON_SIZE}
              className={`contact-icon ${contactIconActive}`}
              onClick={() => setShowContactForm(!showContactForm)}
              onKeyDown={(e) => {
                if (e.key === "Enter") setShowContactForm(!showContactForm);
              }}
            />
          </li>
          <li>
            <a
              title="LinkedIn"
              href="https://se.linkedin.com/in/fredrik-leemann-821b19110"
              rel="noreferrer noopener"
              target="_blank"
            >
              <FaLinkedinIn size={SOCIAL_ICON_SIZE} />
            </a>
          </li>
          <li>
            <a
              title="GitHub"
              href="https://github.com/freddan88"
              rel="noreferrer noopener"
              target="_blank"
            >
              <FaGithubAlt size={SOCIAL_ICON_SIZE} />
            </a>
          </li>
          <li>
            <a
              title="Twitter"
              href="https://twitter.com/FredrikLeemann"
              rel="noreferrer noopener"
              target="_blank"
            >
              <FaTwitter size={SOCIAL_ICON_SIZE} />
            </a>
          </li>
          <li>
            <a
              title="Instagram"
              href="https://www.instagram.com/Freddan_1988"
              rel="noreferrer noopener"
              target="_blank"
            >
              <FaInstagram size={SOCIAL_ICON_SIZE} />
            </a>
          </li>
        </ul>
      </nav>
    </header>
  );
};

export default Header;
