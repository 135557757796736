import { FC } from "react";
import { FaInfoCircle } from "react-icons/fa";
import { IProject } from "../../data/dataTypes";

type TFront = Omit<IProject, "description" | "technologies">;

interface IProps extends TFront {
  setFlippedCard: React.Dispatch<React.SetStateAction<string>>;
}

const CardFront: FC<IProps> = (props) => {
  return (
    <div className="portfolio-card--front rounded">
      <div
        onClick={() => props.setFlippedCard(props.sourceLinkUrl)}
        className="portfolio-card--front__information flex-center-center"
        title="Read more"
      >
        <FaInfoCircle />
      </div>
      <img
        className="portfolio-card--front__image rounded noselect"
        alt="Project for my portfolio"
        src={`images/portfolio/${props.picture}`}
        loading="lazy"
        height="240"
        width="360"
      />
      <div className="portfolio-card--front__titlebar flex-btw-center">
        <strong>{props.title}</strong>
        <span title="Project created" className="tag rounded noselect">
          {props.created}
        </span>
      </div>
      <div className="flex-btw-center">
        <a href={props.actionLinkUrl} rel="noreferrer noopener" target="_blank">
          {props.actionLinkText}
        </a>
        <a href={props.sourceLinkUrl} rel="noreferrer noopener" target="_blank">
          View Source
        </a>
      </div>
    </div>
  );
};

export default CardFront;
