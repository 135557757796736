import { FC } from "react";
import { IProject } from "../../data/dataTypes";
import CardFront from "./CardFront";
import CardBack from "./CardBack";

interface IProps extends IProject {
  cardClass: string | undefined;
  setFlippedCard: React.Dispatch<React.SetStateAction<string>>;
}

const Card: FC<IProps> = (props) => {
  return (
    <div className="portfolio-card rounded">
      <div className={`portfolio-card--inner rounded ${props.cardClass}`}>
        <CardFront
          title={props.title}
          created={props.created}
          picture={props.picture}
          sourceLinkUrl={props.sourceLinkUrl}
          actionLinkUrl={props.actionLinkUrl}
          actionLinkText={props.actionLinkText}
          setFlippedCard={props.setFlippedCard}
        />
        <CardBack
          description={props.description}
          technologies={props.technologies}
          setFlippedCard={props.setFlippedCard}
        />
      </div>
    </div>
  );
};

export default Card;
