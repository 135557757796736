import { FC } from "react";
import { FaBook } from "react-icons/fa";

interface IProps {
  link: string;
  title: string;
  updated: string;
}

const TutorialListing: FC<IProps> = (props) => {
  const lastUpdatedText = `This tutorial were last updated: ${props.updated}`;

  return (
    <a
      href={props.link}
      target="_blank"
      rel="noreferrer noopener"
      className="tutorial-listing rounded"
    >
      <FaBook size={20} />
      <strong>{props.title}</strong>
      <strong
        title={lastUpdatedText}
        aria-label={lastUpdatedText}
        className="noselect tutorial-listing__updated"
      >
        {props.updated}
      </strong>
    </a>
  );
};

export default TutorialListing;
