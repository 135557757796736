import { FC } from "react";
import CloseIcon from "../../components/CloseIcon";

interface IProps {
  description: string;
  technologies: string[];
  setFlippedCard: React.Dispatch<React.SetStateAction<string>>;
}

const renderTechnologies = (technologies: string[]) => {
  return technologies.map((technology) => (
    <span title={technology} className="tag rounded noselect" key={technology}>
      {technology}
    </span>
  ));
};

const CardBack: FC<IProps> = (props) => {
  return (
    <div className="portfolio-card--back rounded">
      <div className="portfolio-card--back__header">
        <CloseIcon handleClick={() => props.setFlippedCard("")} />
      </div>
      <div className="portfolio-card--back__section">
        <p>
          <strong>Description</strong>
          {props.description}
        </p>
        <div>
          <strong>Technologies</strong>
          <div className="technologies-container">
            {renderTechnologies(props.technologies)}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardBack;
