import { IProject } from "./dataTypes";

// prettier-ignore

export const projects: IProject[] = [
  {
    title: "astro-pokedex",
    created: "2022-09-10",
    picture: "astro-pokedex.jpg",
    actionLinkText: "Download file",
    actionLinkUrl: "https://github.com/freddan88/astro-pokedex/archive/refs/heads/main.zip",
    sourceLinkUrl: "https://github.com/freddan88/astro-pokedex",
    description: `Wanted to try the astro-frontend framework and this is the end result.
    I tried this together with a server that I previously built using node.js, typescript and express.js`,
    technologies: ["Astro","Typescript","Javascript","HTML","CSS"],
  },
  {
    title: "node-typescript-01",
    created: "2022-08-31",
    picture: "node-typescript-01.jpg",
    actionLinkText: "Download file",
    actionLinkUrl: "https://github.com/freddan88/node-typescript-01/archive/refs/heads/main.zip",
    sourceLinkUrl: "https://github.com/freddan88/node-typescript-01",
    description: `Api built using Nodejs and Express. This is not built for a specific project and never considered completed.
    If you like the come you may use it as template for your own project`,
    technologies: ["Node.js","Express.js","Typescript","Javascript","Axios"],
  },
  {
    title: "simple-php-oop-mvc-app",
    created: "2022-01-05",
    picture: "simple-php-oop-mvc-app-portfolio.jpg",
    actionLinkText: "Download file",
    actionLinkUrl: "https://github.com/freddan88/simple-php-oop-mvc-app/archive/refs/heads/main.zip",
    sourceLinkUrl: "https://github.com/freddan88/simple-php-oop-mvc-app",
    description: `This project is more of a test were I tried to se if I could program something close to the frameworks like Laravel when it comes to routing.
    I used object-oriented programing in PHP for this and it was a fun project that can be useful so I list it here but will never be a complete website or app`,
    technologies: ["PHP","HTML","CSS","Javascript","SQLite","SQL"],
  },
  {
    title: "simple-php-oop-email-api",
    created: "2021-12-29",
    picture: "simple-php-oop-email-api-portfolio.jpg",
    actionLinkText: "Download file",
    actionLinkUrl: "https://github.com/freddan88/simple-php-oop-email-api/archive/refs/heads/main.zip",
    sourceLinkUrl: "https://github.com/freddan88/simple-php-oop-email-api",
    description: `This is a simple email-api built using object-oriented programing in PHP. This will let you send emails using the built in mail-command.
    The repository will also house an example-form that you can use on the client to communicate`,
    technologies: ["PHP","HTML","CSS","Javascript","Fetch","Axios","API"],
  },
  {
    title: "Persnr-react",
    created: "2021-02-24",
    picture: "persnr-react-portfolio.jpg",
    actionLinkText: "View live",
    actionLinkUrl: "https://persnr-app01.netlify.app/",
    sourceLinkUrl: "https://github.com/freddan88/persnr-react",
    description: `Project were I will automatically format user-provided personal-numbers to the standard format for chosen country.
    I used many custom regex-patterns (Regular expressions) and react.js`,
    technologies: ["HTML","CSS","Javascript","React.js","Regex"],
  },
  {
    title: "Ipxe Linux",
    created: "2020-11-30",
    picture: "portfolio_ipxe1.jpg",
    actionLinkText: 'Read tutorial',
    actionLinkUrl: "https://github.com/freddan88/ipxe-linux/blob/main/README.md",
    sourceLinkUrl: "https://github.com/freddan88/ipxe-linux",
    description: `Scripts that I wrote for a company to Compile and manage a network-booting server on Linux using IPXE and HTTP.
    Those script were used for a long time and were successful so I list them here =)`,
    technologies: ["Windows", "Linux", "Ubuntu", "CentOS", "Bash", "Samba", "Batch", "http", "syslinux", "ipxe", "pxe"],
  },
  {
    title: "Credit Card QL01",
    created: "2020-10-21",
    picture: "credit-card.jpg",
    actionLinkText: 'Download file',
    actionLinkUrl: "https://github.com/freddan88/ChallengeComponents_ql01/archive/refs/heads/main.zip",
    sourceLinkUrl: "https://github.com/freddan88/ChallengeComponents_ql01",
    description: `Coding challenge to validate credit card numbers. I found this project to turn out quite beautiful so thats why I list it here`,
    technologies: ["React.js", "Javascript", "JSX", "CSS", "Context-api"],
  },
  {
    title: "15 Puzzle Game",
    created: "2020-09-06",
    picture: "15-puzzle.jpg",
    actionLinkText: 'View live',
    actionLinkUrl: "https://15-puzzle-game-fl01.netlify.app/",
    sourceLinkUrl: "https://github.com/freddan88/15-puzzle-game",
    description: `Coding challenge to create puzzle game in react. This game is called the 15-puzzle and the rules are to order the numbers.
    My version have a bug that will let you cheat. I found this game to turn out quite beautiful so thats why I list it here`,
    technologies: ["React.js", "Javascript", "JSX", "Lodash", "styled-components"],
  },
  {
    title: "Advanced ChatApp",
    created: "2020-03-08",
    picture: "advanced-chat-app-portfolio.jpg",
    actionLinkText: 'Download file',
    actionLinkUrl: "https://github.com/freddan88/AdvancedChatapp/archive/refs/heads/master.zip",
    sourceLinkUrl: "https://github.com/freddan88/AdvancedChatapp",
    description: `Project to create backend and frontend for a chat-application.
    There are a bug in the code that will slow down communication between backend and frontend after some messages.
    Would like to understand why this happens so if you know the answer please contact me =)`,
    technologies: ["HTML","CSS","Javascript","React.js","Node.js","Socket.io","express"],
  },
  {
    title: "Rörmossenshumle",
    created: "2019-11-15",
    picture: "examensarbete-yrgo.png",
    actionLinkText: "View live",
    actionLinkUrl: "https://www.rormossenshumle.se",
    sourceLinkUrl: "https://github.com/freddan88/Yrgo-examensarbete-frontend",
    description: `The master thesis for my school (Yrgo) contained of a written part that we handed in but also a website.
    This page is written using react and is for a real company producing hops and selling to breweries`,
    technologies: ["React.js", "Javascript", "Wordpress", "Rest API", "SASS", "JSON", "JSX", "PHP"],
  },
  {
    title: "Game Over",
    created: "2019-05-27",
    picture: "portfolio_dinoball_pinball.jpg",
    actionLinkText: 'View live',
    actionLinkUrl: "https://dinoball.netlify.com",
    sourceLinkUrl: "https://github.com/freddan88/game-over",
    description: `For this school-assignment we worked two and two to create a game. We had an idea for a pinball-game and to our help we used the physics-engine Matter.js`,
    technologies: ["HTML", "CSS", "Javascript", "Phaser3", "Matter.js"],
  },
  {
    title: "React-Slider",
    created: "2019-03-25",
    picture: "react-slider.jpg",
    actionLinkText: 'View live',
    actionLinkUrl: "https://react-slider.netlify.app",
    sourceLinkUrl: "https://github.com/freddan88/react-slider",
    description: `School-assignment for the react-course. We were challenged to create an image-slider using react and at least one json-api to retrieve data from`,
    technologies: ["Javascript", "React.js", "JSX", "JSON", "API"],
  },
  {
    title: "Freshop",
    created: "2019-03-20",
    picture: "freshop-c_sharp.jpg",
    actionLinkText: 'Download file',
    actionLinkUrl: "https://github.com/freddan88/freshop/archive/master.zip",
    sourceLinkUrl: "https://github.com/freddan88/freshop",
    description: `School-assignment to create a simple faked e-commerce website with a backend written in C#.
    This fake-store sells network-hardware with brands like CISCO since I worked and have education with such products`,
    technologies: ["HTML", "CSS", "Javascript", "Rest API", "C#", "SQL", "SQLite3"],
  },
  {
    title: "smrpg_build",
    created: "2019-03-01",
    picture: "portfolio_smrpg.gif",
    actionLinkText: 'Watch video',
    actionLinkUrl: "https://www.youtube.com/playlist?list=PLgwpw8nEhjkXKJraFGufnOkAuJCvEpYX9",
    sourceLinkUrl: "https://github.com/freddan88/smrpg_build",
    description: `Project were I wrote a script in bash and produced a video on YouTube.
    The idea were to help people compile a modification called "smrpg" used in game-servers playing Counter-Strike Source and having sourcemod installed`,
    technologies: ["Bash", "Babun", "SRCDS", "Sourcemod", "Windows", "Linux", "Ubuntu", "CentOS"],

  },
  {
    title: "Guessing Game 2",
    created: "2019-02-27",
    picture: "portfolio_c-sharp1.jpg",
    actionLinkText: 'Download file',
    actionLinkUrl: "https://github.com/freddan88/guessingGame2/archive/master.zip",
    sourceLinkUrl: "https://github.com/freddan88/guessingGame2",
    description: `School-assignment to create a terminal-based app to guess random numbers in C#. To test this application you will need to first compile it`,
    technologies: ["C#", "CLI", "Console", "Terminal"],
  },
  {
    title: "Photoify",
    created: "2019-01-17",
    picture: "portfolio_photoify.jpg",
    actionLinkText: 'Download file',
    actionLinkUrl: "https://github.com/freddan88/photoify/archive/master.zip",
    sourceLinkUrl: "https://github.com/freddan88/photoify",
    description: `Christmas assignment in school. Our mission were to create a social network clone using PHP and SQL.
    Database of choice is sqlite which is a simple file-based database`,
    technologies: ["Javascript", "SQLite3", "HTML", "CSS", "PHP", "SQL"],
  },
  {
    title: "Randomgif",
    created: "2019-01-08",
    picture: "portfolio_randomgif.jpg",
    actionLinkText: 'View live',
    actionLinkUrl: "https://freddan88.github.io/randomgif",
    sourceLinkUrl: "https://github.com/freddan88/randomgif",
    description: `Project from school - App to search for GIF:s using giphy-api. For this assignment we practiced to use the built in browsers fetch-api`,
    technologies: ["Javascript", "HTML", "CSS", "NPM", "JSON", "API"],
  },
  {
    title: "One Pager",
    created: "2018-12-13",
    picture: "portfolio_one-pager.jpg",
    actionLinkText: "View live",
    actionLinkUrl: "https://freddan88.github.io/one-pager",
    sourceLinkUrl: "https://github.com/freddan88/one-pager",
    description: `School-assignment were we worked together two and two to create a fake landing-page for a product. In our case this were a smart-watch`,
    technologies: ["Javascript", "HTML", "CSS"],
  },
  {
    title: "Fuzzy Memory",
    created: "2018-12-04",
    picture: "portfolio_fuzzy-memory.jpg",
    actionLinkText: "View live",
    actionLinkUrl: "http://www.leemann.se/fredrik/project/fuzzy-memory",
    sourceLinkUrl: "https://github.com/freddan88/Yrgo-assignments/tree/master/04.JavaScript/fuzzy-memory",
    description: `Javascript-project from school. We were challenged to crete a game using javascript so I created a simpsons-themed memory game`,
    technologies: ["Javascript", "HTML", "CSS"],
  },
  {
    title: "FastSecurity - FS1.0",
    created: "2018-11-30",
    picture: "portfolio_fs1.jpg",
    actionLinkText: "View live",
    actionLinkUrl: "http://www.leemann.se/fredrik/project/fast-security",
    sourceLinkUrl: "https://github.com/freddan88/Yrgo-assignments/tree/master/05.Projects/FastSecurity",
    description: `School-assignment together with other classes. Our mission were to create a fake landing-page for a futuristic car with custom images and coding`,
    technologies: ["Javascript", "HTML", "CSS", "PHP"],
  },
  {
    title: "Fake News",
    created: "2018-10-31",
    picture: "portfolio_fake-news.jpg",
    actionLinkText: "View live",
    actionLinkUrl: "http://www.leemann.se/fredrik/project/fake-news",
    sourceLinkUrl: "https://github.com/freddan88/Yrgo-assignments/tree/master/03.PHP/fake-news",
    description: `First school-assignment in PHP. We were challenged to create a page called fake-news to practice loops in PHP`,
    technologies: ["Javascript", "HTML", "CSS", "PHP"],
  },
  {
    title: "SRCDS Monitor Web",
    created: "2018-09-01",
    picture: "portfolio_srcds1.jpg",
    actionLinkText: 'Download file',
    actionLinkUrl: "http://www.leemann.se/fredrik/file_downloads/srcds_monitor1.zip",
    sourceLinkUrl: "https://github.com/freddan88/srcds-monitor-web",
    description: `Project to monitor Valves game-servers (SRCDS) Built using PHP and tested for servers playing: Counter-Strike Source`,
    technologies: ["PHP", "CSS", "HTML", "SRCDS"],
  },
  {
    title: "Mediasorter Windows",
    created: "2018-08-19",
    picture: "mediasorter.gif",
    actionLinkText: 'Download file',
    actionLinkUrl: "http://www.leemann.se/fredrik/file_downloads/mediasorter_windows-cmd.zip",
    sourceLinkUrl: "https://github.com/freddan88/mediasorter-windows",
    description: `Project to sort photos/videos using a batch-script from included metadata on Windows.
    Those scripts dependents on "ExifTool by Phil Harvey" to extract information from the files`,
    technologies: ["Windows", "Batch", "CMD"],
  },
  {
    title: "Ts3srv Linux",
    created: "2018-07-24",
    picture: "portfolio_ts3srv.gif",
    actionLinkText: 'Download file',
    actionLinkUrl: "http://www.leemann.se/fredrik/file_downloads/ts3srv_linux-init.zip",
    sourceLinkUrl: "https://github.com/freddan88/ts3srv-linux",
    description: `Script meant to be used on a debian-based linux-server. This script is written in bash and will help you manage and install TeamSpeak3 Servers`,
    technologies: ["PHP", "Bash", "Linux", "Ubuntu", "CentOS", "TeamSpeak3", "MySQL", "SQLite3"],
  },
  {
    title: "Httpsrv 2.0 Linux",
    created: "2018-05-22",
    picture: "portfolio_httpsrv2.gif",
    actionLinkText: 'Download file',
    actionLinkUrl: "https://github.com/freddan88/httpsrv-linux/archive/refs/heads/master.zip",
    sourceLinkUrl: "https://github.com/freddan88/httpsrv-linux",
    description: `Project were I compiled the web-server apache on a linux-server from scratch. I produced a script in bash to help compile and manage the servers`,
    technologies: ["Linux", "Ubuntu", "CentOS", "Bash", "Apache", "PHP", "phpMyAdmin", "MySQL", "LAMP", "HTTP"],
  },
  {
    title: "Pxesrv 2.0 Linux",
    created: "2018-05-22",
    picture: "pxesrv2.gif",
    description: `Scripts that I wrote for a company to Compile and manage a network-booting server on Linux using GPXE and TFTP.
    Those script were used for several years and were successful so I list them here =)`,
    technologies: ["Windows", "Linux", "Ubuntu", "CentOS", "Bash", "Batch", "Samba", "Winpe", "Dnsmasq", "Lighttpd", "syslinux", "gpxe", "pxe"],
    sourceLinkUrl: "https://github.com/freddan88/pxesrv-linux",
    actionLinkUrl: "https://github.com/freddan88/pxesrv-linux/archive/refs/heads/master.zip",
    actionLinkText: 'Download file'
  },
];
