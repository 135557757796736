import { IContainers } from "./dataTypes";

// prettier-ignore

export const containersData: IContainers[] = [
  {
    header: "Tutorials",
    link: "/tutorials",
    content: "I write some tutorials to host on github. I usually record videos for youtube around those for some projects",
    internal: true,
    align: "left",
  },
  {
    header: "Portfolio",
    link: "/portfolio",
    content: "This page will get updated quite frequently code-projects. Don't forget to check this page for updates",
    internal: true,
    align: "right",
  },
  {
    header: "Repositories",
    link: "https://github.com/freddan88",
    content: "I have a page on Github, were I store code for my latest projects. Check them out by clicking the link above",
    internal: false,
    align: "left",
  },
];
