import { FC, useCallback, useEffect, useState } from "react";
import axios from "axios";
import CloseIcon from "./CloseIcon";
import ContactFormCaptcha from "./ContactFormCaptcha";

export interface IGlobalMessage {
  message: string;
  success: boolean;
}

interface IFormErrors {
  senderEmail?: string;
  emailMessage?: string;
}
interface IProps {
  showContactForm: boolean;
  setShowContactForm: React.Dispatch<React.SetStateAction<boolean>>;
  setGlobalMessage: React.Dispatch<
    React.SetStateAction<IGlobalMessage | undefined>
  >;
}

const networkError = {
  message: "Email could not be sent due to network-errors",
  success: false,
} as IGlobalMessage;

const generalError = {
  message: "Email could not be sent due to errors",
  success: false,
} as IGlobalMessage;

const emailSuccess = {
  message: "Email sent successfully",
  success: true,
} as IGlobalMessage;

const emailSubject = process.env.REACT_APP_EMAIL_SUBJECT!;
const emailApiUrl = process.env.REACT_APP_EMAIL_API_URL!;
const emailApiKey = process.env.REACT_APP_EMAIL_APIKEY!;
const emailSendTo = process.env.REACT_APP_EMAIL_TO!;

const ContactForm: FC<IProps> = (props) => {
  const [formErrors, setFormErrors] = useState<IFormErrors>();
  const [captchaValid, setCaptchaValid] = useState(false);
  const [captchaSecret, setCaptchaSecret] = useState("");

  const { showContactForm, setShowContactForm, setGlobalMessage } = props;

  const formVisibleClass = showContactForm ? "show" : "";

  const generateNewCaptchaSecret = useCallback(() => {
    const newCaptcha = Math.random().toString().substring(2, 10);
    setCaptchaSecret(newCaptcha);
  }, [setCaptchaSecret]);

  const handleSubmit = useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      if (captchaValid) {
        const contactForm = e.target as HTMLFormElement;
        const formData = new FormData(contactForm);

        formData.append("emailSubject", emailSubject);
        formData.append("emailTo", emailSendTo);
        formData.append("apikey", emailApiKey);

        axios
          .post(emailApiUrl, formData)
          .then((data) => {
            if (data.status !== 200) {
              throw new Error(`Status: ${data.status}`);
            }
            setGlobalMessage(emailSuccess);
          })
          .catch((error) => {
            if (error.code === "ERR_NETWORK") {
              setGlobalMessage(networkError);
            } else if ("fieldErrors" in error.response.data) {
              setFormErrors(error.response.data.fieldErrors);
            } else {
              setGlobalMessage(generalError);
            }
            console.error(error.message);
          });
      } else {
        setGlobalMessage(generalError);
      }
    },
    [setGlobalMessage, setFormErrors, captchaValid]
  );

  useEffect(() => {
    generateNewCaptchaSecret();
    setCaptchaValid(false);
  }, [showContactForm, generateNewCaptchaSecret]);

  return (
    <div className={`contact-form rounded ${formVisibleClass}`}>
      <CloseIcon
        className="close-form"
        handleClick={() => setShowContactForm(false)}
      />
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="senderName">Your Name</label>
          <input
            id="senderName"
            name="senderName"
            type="text"
            placeholder="Enter your Name"
          />
        </div>

        <div className="form-group">
          <label htmlFor="senderEmail">Your email*</label>
          <input
            id="senderEmail"
            name="senderEmail"
            type="email"
            placeholder="Enter your email"
            required
            onFocus={() =>
              setFormErrors({
                ...formErrors,
                senderEmail: "",
              })
            }
          />
          <small>{formErrors && formErrors?.senderEmail}</small>
        </div>

        <div className="form-group">
          <label htmlFor="emailMessage">Your message*</label>
          <textarea
            id="emailMessage"
            name="emailMessage"
            placeholder="Enter your message"
            required
            onFocus={() =>
              setFormErrors({
                ...formErrors,
                emailMessage: "",
              })
            }
          ></textarea>
          <small>{formErrors && formErrors?.emailMessage}</small>
        </div>
        {showContactForm && (
          <ContactFormCaptcha
            captchaValid={captchaValid}
            setCaptchaValid={setCaptchaValid}
            captchaSecret={captchaSecret}
          />
        )}

        <button type="submit" disabled={!captchaValid}>
          Send Email
        </button>
      </form>
    </div>
  );
};

export default ContactForm;
