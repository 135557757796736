import { FC } from "react";

interface IProps {
  message: string;
  success: boolean;
}

const StatusBadge: FC<IProps> = (props) => {
  const statusClass = props.success ? "success" : "failure";

  return (
    <div className={`status-badge ${statusClass}`}>
      <strong>{props.message}</strong>
    </div>
  );
};

export default StatusBadge;
