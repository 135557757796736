import { FC, useEffect, useState, useCallback, useMemo } from "react";
import { Helmet } from "react-helmet-async";
import { orderBy } from "lodash";
import { searchByQuery } from "../../utils/searchFunction";
import { projects } from "../../data/portfolio";
import { IProject } from "../../data/dataTypes";
import SearchBar from "../../components/SearchBar";
import Card from "./Card";

interface IProps {}

const pageTitle = "Fredrik | Portfolio";
const pageAbout = "Projects and Source Code up on GitHub + live preview";
const pageKeys = "Freddan, Fps_sWe, F3L, FreLee54, Portfolio, GitHub, Preview";
const pageUrl = `${process.env.REACT_APP_WEBSITE_URL}/portfolio`;

const searchBarHelperText = "Search for: title / technology / date-created";

const renderPortfolioCards = (
  setFlippedCard: React.Dispatch<React.SetStateAction<string>>,
  flippedCardUrl: string,
  projects: IProject[]
) => {
  return projects.map((obj) => {
    const isFlipped = flippedCardUrl === obj.sourceLinkUrl;
    const cardClass = isFlipped ? "flipped" : undefined;
    return (
      <Card
        key={obj.sourceLinkUrl}
        setFlippedCard={setFlippedCard}
        cardClass={cardClass}
        {...obj}
      />
    );
  });
};

const Portfolio: FC<IProps> = (props) => {
  const [filteredProjects, setFilteredProjects] = useState<IProject[]>([]);
  const [flippedCardUrl, setFlippedCardUrl] = useState("");

  const orderedProjects = useMemo(
    () => orderBy(projects, ["created"], ["desc"]),
    []
  );

  const totalProjects = orderedProjects.length;
  const totalFilteredProjects = filteredProjects.length;

  const handleSearch = useCallback(
    (searchQuery: string) => {
      const searchResult = searchByQuery(
        orderedProjects,
        ["title", "technologies", "created"],
        searchQuery
      );
      const orderedResult = orderBy(searchResult, ["created"], ["desc"]);
      setFilteredProjects(orderedResult);
    },
    [orderedProjects, setFilteredProjects]
  );

  useEffect(() => {
    setFlippedCardUrl("");
  }, [filteredProjects]);

  useEffect(() => {
    setFilteredProjects(orderedProjects);
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={pageAbout} />
        <meta name="keywords" content={pageKeys} />
        <link rel="canonical" href={pageUrl} />
      </Helmet>
      <main className="flex-available-height">
        <h1 className="noselect">PORTFOLIO</h1>
        <SearchBar
          totalResults={totalProjects}
          filteredResults={totalFilteredProjects}
          helperText={searchBarHelperText}
          searchFunction={handleSearch}
        />
        <section className="grid-section">
          {renderPortfolioCards(
            setFlippedCardUrl,
            flippedCardUrl,
            filteredProjects
          )}
        </section>
      </main>
    </>
  );
};

export default Portfolio;
