import { useEffect, useState } from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
import ContactForm, { IGlobalMessage } from "./components/ContactForm";
import Header from "./components/Header";
import Portfolio from "./pages/Portfolio";
import Tutorials from "./pages/Tutorials";
import Home from "./pages/Home";
import "./assets/styling/styles.scss";
import StatusBadge from "./components/StatusBadge";

const App = () => {
  const [showContactForm, setShowContactForm] = useState(false);
  const [globalMessage, setGlobalMessage] = useState<IGlobalMessage>();

  useEffect(() => {
    setShowContactForm(false)
    const messageTimer = setTimeout(() => {
      setGlobalMessage(undefined);
    }, 2000);
    return () => {
      clearTimeout(messageTimer);
    };
  }, [globalMessage]);

  return (
    <HelmetProvider>
      {globalMessage && (
        <StatusBadge
          message={globalMessage.message}
          success={globalMessage.success}
        />
      )}
      <BrowserRouter>
        <div className="page-container rounded flex-available-height">
          <Header
            showContactForm={showContactForm}
            setShowContactForm={setShowContactForm}
          />
          <div className="content-container flex-available-height">
            <Routes>
              <Route path="/home" element={<Home />} />
              <Route path="/tutorials" element={<Tutorials />} />
              <Route path="/portfolio" element={<Portfolio />} />
              <Route path="*" element={<Navigate replace to="/home" />} />
            </Routes>
          </div>
          <ContactForm
            showContactForm={showContactForm}
            setShowContactForm={setShowContactForm}
            setGlobalMessage={setGlobalMessage}
          />
        </div>
      </BrowserRouter>
    </HelmetProvider>
  );
};

export default App;
